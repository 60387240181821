// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./utils/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={clientId}>
    <BrowserRouter>
      <AuthProvider>
        {/* Wrapping the App within AuthProvider allows any page to access contents of the authContext (user profile info) from any page using the useAuth hook */}
        <App />
      </AuthProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);
