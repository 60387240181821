import { lazy } from "react";
import Loadable from "../utils/Loadable";
import Home from "../pages/Home";

const ForgotPassword = Loadable(lazy(() => import("../pages/auth/ForgotPassword")));
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const Verify = Loadable(lazy(() => import("../pages/auth/Verify")));
const ListTop = Loadable(lazy(() => import("../pages/list/ListTop")));
const RadList = Loadable(lazy(() => import("../pages/list/RadList")));
const HskList = Loadable(lazy(() => import("../pages/list/HskList")));
const DomList = Loadable(lazy(() => import("../pages/list/DomList")));
const DuoList = Loadable(lazy(() => import("../pages/list/DuoList")));
const RadStudy = Loadable(lazy(() => import("../pages/study/RadStudy")));
const Study = Loadable(lazy(() => import("../pages/study/Study")));
const DuoStudy = Loadable(lazy(() => import("../pages/study/DuoStudy")));
const DomStudy = Loadable(lazy(() => import("../pages/study/DomStudy")));
const ParonymStudy = Loadable(lazy(() => import("../pages/study/ParonymStudy")));
const ParamorphStudy = Loadable(lazy(() => import("../pages/study/ParamorphStudy")));
const PolyphoneStudy = Loadable(lazy(() => import("../pages/study/PolyphoneStudy")));
const SpeechStudy = Loadable(lazy(() => import("../pages/study/SpeechStudy")));
const Parts = Loadable(lazy(() => import("../pages/quiz/Parts"))); // Proof of concept for dynamic accordion
const RadQuiz = Loadable(lazy(() => import("../pages/quiz/RadQuiz")));
const DomQuiz = Loadable(lazy(() => import("../pages/quiz/DomQuiz")));
const DuoQuiz = Loadable(lazy(() => import("../pages/quiz/DuoQuiz")));
const ParonymQuiz = Loadable(lazy(() => import("../pages/quiz/ParonymQuiz")));
const ParamorphQuiz = Loadable(lazy(() => import("../pages/quiz/ParamorphQuiz")));
const SpeechQuiz = Loadable(lazy(() => import("../pages/quiz/SpeechQuiz")));
const UserTop = Loadable(lazy(() => import("../pages/user/UserTop")));
const UserFlag = Loadable(lazy(() => import("../pages/user/UserFlag")));
const UserPriority = Loadable(lazy(() => import("../pages/user/UserPriority")));
const UserDiscuss = Loadable(lazy(() => import("../pages/user/UserDiscuss")));
const UserSettings = Loadable(lazy(() => import("../pages/user/UserSettings")));
const UserSubscription = Loadable(lazy(() => import("../pages/user/UserSubscription")));
const PwReset = Loadable(lazy(() => import("../pages/user/PwReset")));
const ResourcesTop = Loadable(lazy(() => import("../pages/resources/ResourcesTop")));
const Faq = Loadable(lazy(() => import("../pages/resources/Faq")));
const ChangeLog = Loadable(lazy(() => import("../pages/resources/ChangeLog")));
const Pronunciation = Loadable(lazy(() => import("../pages/resources/Pronunciation")));
const PrivacyPolicy = Loadable(lazy(() => import("../pages/footer/PrivacyPolicy")));
const TermsOfService = Loadable(lazy(() => import("../pages/footer/TermsOfService")));
const About = Loadable(lazy(() => import("../pages/footer/About")));
const Contact = Loadable(lazy(() => import("../pages/footer/Contact")));
const UserBillofRights = Loadable(lazy(() => import("../pages/footer/UserBillofRights")));

// path appears in the URL, name appears in the menu, parent determines the menu hierarchy

export const nav = [
  { path: "/", name: "Home", parent: "none", isPrivate: false, element: <Home /> },
  { path: "/login", name: "Login", parent: "none", isPrivate: false, element: <Login /> },
  { path: "/register", name: "Register", parent: "none", isPrivate: false, element: <Register /> },
  { path: "/verify", name: "Verify", parent: "none", isPrivate: false, element: <Verify /> },
  { path: "/forgotpassword", name: "Forgot Password", parent: "none", isPrivate: false, element: <ForgotPassword /> },
  { path: "/privacy", name: "Privacy Policy", parent: "Footer", isPrivate: false, element: <PrivacyPolicy /> },
  { path: "/terms", name: "Terms of Service", parent: "Footer", isPrivate: false, element: <TermsOfService /> },
  {
    path: "/billofrights",
    name: "User Bill of Rights",
    parent: "Footer",
    isPrivate: false,
    element: <UserBillofRights />,
  },
  { path: "/list/", name: "List", parent: "top", isPrivate: true, element: <ListTop /> },
  { path: "/list/radical", name: "Radicals", parent: "List", isPrivate: true, element: <RadList /> },
  { path: "/list/hsk", name: "HSK", parent: "List", isPrivate: true, element: <HskList /> },
  { path: "/list/domino", name: "Domino", parent: "List", isPrivate: true, element: <DomList /> },
  { path: "/list/duolingo", name: "Duolingo", parent: "List", isPrivate: true, element: <DuoList /> },
  { path: "/study/radical", name: "Radicals", parent: "Study", isPrivate: true, element: <RadStudy /> },
  { path: "/study", name: "Domino", parent: "none", isPrivate: true, element: <Study /> },
  { path: "/study/duolingo", name: "Duolingo", parent: "Study", isPrivate: true, element: <DuoStudy /> },
  { path: "/study/domino", name: "Domino", parent: "Study", isPrivate: true, element: <DomStudy /> },
  { path: "/study/paronym", name: "Paronyms", parent: "Study", isPrivate: true, element: <ParonymStudy /> },
  { path: "/study/paramorph", name: "Paramorphs", parent: "Study", isPrivate: true, element: <ParamorphStudy /> },
  { path: "/study/polyphone", name: "Polyphones", parent: "Study", isPrivate: true, element: <PolyphoneStudy /> },
  { path: "/study/speech", name: "Speech", parent: "Study", isPrivate: true, element: <SpeechStudy /> },
  { path: "/quiz/parts", name: "Parts", parent: "none", isPrivate: true, element: <Parts /> },
  { path: "/quiz/radical", name: "Radicals", parent: "Quiz", isPrivate: true, element: <RadQuiz /> },
  { path: "/quiz/domino", name: "Domino", parent: "Quiz", isPrivate: true, element: <DomQuiz /> },
  { path: "/quiz/duolingo", name: "Duolingo", parent: "Quiz", isPrivate: true, element: <DuoQuiz /> },
  { path: "/quiz/paronym", name: "Paronyms", parent: "Quiz", isPrivate: true, element: <ParonymQuiz /> },
  { path: "/quiz/paramorph", name: "Paramorphs", parent: "Quiz", isPrivate: true, element: <ParamorphQuiz /> },
  { path: "/quiz/speech", name: "Speech", parent: "Quiz", isPrivate: true, element: <SpeechQuiz /> },
  { path: "/resources/", name: "Resources", parent: "top", isPrivate: true, element: <ResourcesTop /> },
  {
    path: "/resources/pronunciation",
    name: "Pronunciation",
    parent: "Resources",
    isPrivate: true,
    element: <Pronunciation />,
  },
  { path: "/resources/faq", name: "FAQ", parent: "Resources", isPrivate: true, element: <Faq /> },
  { path: "/resources/changelog", name: "Change Log", parent: "Resources", isPrivate: true, element: <ChangeLog /> },
  { path: "/contact", name: "Contact", parent: "none", isPrivate: false, element: <Contact /> },
  { path: "/about", name: "About", parent: "none", isPrivate: false, element: <About /> },
  { path: "/user/", name: "Stats", parent: "My Content", isPrivate: true, element: <UserTop /> },
  { path: "/user/flag", name: "Flag", parent: "My Content", isPrivate: true, element: <UserFlag /> },
  { path: "/user/priority", name: "Priority", parent: "My Content", isPrivate: true, element: <UserPriority /> },
  { path: "/user/discuss", name: "Discuss", parent: "My Content", isPrivate: true, element: <UserDiscuss /> },
  { path: "/user/settings", name: "Settings", parent: "none", isPrivate: true, element: <UserSettings /> },
  { path: "/user/pwreset", name: "Password Reset", parent: "none", isPrivate: true, element: <PwReset /> },
  {
    path: "/user/subscription",
    name: "Subscription",
    parent: "none",
    isPrivate: true,
    element: <UserSubscription />,
  },
];
