import React, { createContext, useContext, useState, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProviderWrapper = ({ children }) => {
  const [dark, setDark] = useState(true);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: dark ? "dark" : "light",
          primary: {
            main: dark ? "rgb(144, 202, 249)" : "rgb(25, 118, 210)",
          },
          secondary: {
            main: dark ? "#f48fb1" : "hsl(39,100%,92%)",
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                margin: 0,
                padding: 0,
                height: "100vh",
                backgroundColor: "background.default",
                color: "text.primary",
              },
              html: {
                margin: 0,
                padding: 0,
                height: "100%",
              },
            },
          },
          MuiButton: {
            defaultProps: {
              variant: "outlined", // Unselected
            },
            styleOverrides: {
              root: {
                // textTransform: "none", // Removes uppercase text transformation
                // fontWeight: "bold",
                borderRadius: "6px",
                padding: "10px 20px",
                border: "2px solid",
                color: dark ? "hsl(37,100%,92%)" : "hsl(0,0%,22%)",
              },
              contained: {
                // Selected
                backgroundColor: dark ? "hsl(39,100%,32%)" : "hsl(39,100%,50%)",
                borderColor: dark ? "hsl(39,100%,92%)" : "hsl(39,100%,92%)",
                "&:hover": {
                  backgroundColor: dark ? "hsl(39,100%,32%)" : "hsl(39,100%,50%)",
                },
              },
              outlined: {
                // Normal (Unselected)
                backgroundColor: dark ? "hsl(39,100%,16%)" : "hsl(39,100%,92%)",
                borderColor: dark ? "hsl(39,100%,92%)" : "hsl(39,100%,50%)",
                "&:hover": {
                  backgroundColor: dark ? "hsl(39,100%,32%)" : "hsl(39,100%,50%)",
                  borderColor: dark ? "hsl(39,100%,16%)" : "hsl(39,100%,92%)",
                },
              },
            },
          },
          MuiAvatar: {
            styleOverrides: {
              root: {
                backgroundColor: dark ? "hsl(39,100%,32%)" : "hsl(39,100%,50%)",
                color: "white",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: dark ? "hsl(0,0%,7%)" : "hsl(37,100%,98%)",
              },
            },
          },
          MuiSlider: {
            styleOverrides: {
              root: {
                color: dark ? "hsl(39,100%,60%)" : "hsl(39,100%,50%)", // Track color
              },
              thumb: {
                backgroundColor: dark ? "hsl(39,100%,80%)" : "hsl(39,100%,50%)", // Handle color
                "&:hover": {
                  backgroundColor: dark ? "hsl(39,100%,90%)" : "rgb(255, 180, 30)",
                },
              },
              track: {
                backgroundColor: dark ? "hsl(39,100%,70%)" : "hsl(39,100%,50%)",
              },
              rail: {
                backgroundColor: dark ? "hsl(39,100%,30%)" : "hsl(39,100%,92%)", // Unfilled track
              },
            },
          },
          // ✅ Customize MUI SWITCH
          MuiSwitch: {
            styleOverrides: {
              root: {
                width: 42,
                height: 26,
                margin: "0px 8px",
                padding: 0,
                "& .MuiSwitch-switchBase": {
                  padding: 1,
                  margin: 2,
                  transitionDuration: "300ms",
                  "&.Mui-checked": {
                    transform: "translateX(16px)",
                    color: "#fff",
                    "& + .MuiSwitch-track": {
                      backgroundColor: dark ? "hsl(39,100%,20%)" : "hsl(39,100%,92%)", // track when Enabled
                      opacity: 1,
                    },
                  },
                },
                "& .MuiSwitch-thumb": {
                  width: 22,
                  height: 22,
                  backgroundColor: dark ? "hsl(39,100%,80%)" : "hsl(39,100%,50%)",
                },
                "& .MuiSwitch-track": {
                  borderRadius: 13,
                  backgroundColor: dark ? "hsl(39,100%,20%)" : "hsl(39,100%,92%)",
                  opacity: 1,
                },
              },
            },
          },
          // MuiSvgIcon: {
          //   styleOverrides: {
          //     root: {
          //       color: dark ? "hsl(39,100%,80%)" : "hsl(39,100%,30%)", // Default icon color
          //     },
          //   },
          // },
          // MuiIconButton: {
          //   styleOverrides: {
          //     root: {
          //       color: dark ? "hsl(39,100%,80%)" : "hsl(39,100%,30%)", // Default color for IconButtons
          //       "&:hover": {
          //         color: dark ? "hsl(39,100%,90%)" : "hsl(39,100%,50%)", // Hover effect
          //       },
          //     },
          //   },
          // },
        },
      }),
    [dark]
  );

  return (
    <ThemeContext.Provider value={{ dark, setDark }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>{" "}
    </ThemeContext.Provider>
  );
};
