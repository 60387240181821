import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserMenu from "./UserMenu";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Toolbar, Typography, IconButton, Tooltip } from "@mui/material";
import { Brightness4, Brightness7, Home } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useThemeContext } from "./ThemeContext"; // Import the custom hook
import useAuth from "../hooks/useAuth";

const drawerWidth = 200;

const NavBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const TopNavBar = ({ open, setOpen }) => {
  const { currentUser, setCurrentUser } = useAuth();
  const { dark, setDark } = useThemeContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser.darkMode !== undefined) {
      setDark(Boolean(currentUser.darkMode));
    }
  }, [currentUser.darkMode, setDark]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDarkModeToggle = () => {
    setDark((prevDark) => {
      const newDarkMode = !prevDark;
      console.log("newDarkMode:", newDarkMode);
      setCurrentUser((prevUser) => ({ ...prevUser, darkMode: newDarkMode }));
      return newDarkMode;
    });
  };

  return (
    <NavBar
      position="fixed"
      open={open}
      elevation={0}
      sx={{
        backgroundColor: dark ? "rgb(81,81,81)" : "rgb(255,239,213)",
        border: dark ? "none" : "2px solid",
        borderColor: dark ? "rgb(255, 165, 0)" : "rgb(255, 165, 0)",
        color: dark ? "white" : "rgba(0, 0, 0, 0.87)",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={[
            {
              marginRight: 5,
            },
            open && { display: "none" },
          ]}
        >
          <MenuIcon />
        </IconButton>
        <Tooltip title="Go back to home page">
          <IconButton sx={{ mr: 1 }} onClick={() => navigate("/")}>
            <Home />
          </IconButton>
        </Tooltip>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, mt: 0.25 }}>
          HSK Cards
        </Typography>
        <IconButton onClick={handleDarkModeToggle}>{dark ? <Brightness7 /> : <Brightness4 />}</IconButton>
        <UserMenu />
      </Toolbar>
    </NavBar>
  );
};

export default TopNavBar;
