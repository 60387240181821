import React from "react";
import { ThemeProviderWrapper } from "./structure/ThemeContext";
import { Routes, Route } from "react-router-dom";
import Layout from "./structure/Layout";
import LayoutPublic from "./structure/LayoutPublic";
import PersistLogin from "./utils/PersistLogin";
import RequireAuth from "./utils/RequireAuth";
import Missing from "./pages/auth/Missing";
import Quiz from "./pages/quiz/Quiz";
import { nav } from "./structure/navigation";
const App = () => {
  return (
    <ThemeProviderWrapper>
      <Routes>
        <Route element={<LayoutPublic />}>
          {/* Public Routes */}
          {nav
            .filter((item) => !item.isPrivate)
            .map((item) => (
              <Route key={item.path} path={item.path} element={item.element} />
            ))}
        </Route>
        <Route element={<Layout />}>
          {/* Protected Routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              {nav
                .filter((item) => item.isPrivate)
                .map((item) => (
                  <Route key={item.path} path={item.path} element={item.element} />
                ))}
            </Route>
          </Route>

          {/* Catch-all for missing routes */}
          <Route path="*" element={<Missing />} />
        </Route>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/quiz" element={<Quiz />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProviderWrapper>
  );
};

export default App;
