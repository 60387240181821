import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopNavBar from "./TopNavBar";
import SideList from "./SideList";
import Footer from "./Footer";

const Layout = () => {
  const [open, setOpen] = useState(false);
  const [dark, setDark] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}>
      <CssBaseline />
      {/* Navbar */}
      <TopNavBar open={open} setOpen={setOpen} dark={dark} setDark={setDark} />
      {/* Sidebar and Content */}
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <SideList open={open} setOpen={setOpen} isMobile={isMobile} />
        {/* Main Content Area */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: 4,
            transition: "margin 0.3s ease-out",
            marginLeft: open ? "8px" : "8px",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Layout;
