import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Tooltip,
  Typography,
  SwipeableDrawer,
} from "@mui/material";
import { ChevronLeft, ExpandLess, ExpandMore, Folder, Quiz, School, Person, MenuBook } from "@mui/icons-material";
import { nav } from "./navigation";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`, // Mini width when closed
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  transition: "width 0.3s ease-out",

  "& .MuiDrawer-paper": {
    borderColor: "rgb(255, 165, 0)",
    position: "absolute", // Prevents affecting layout when closed
    width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`, // Mini width
    visibility: "visible", // Keep icons always visible
    ...(open ? openedMixin(theme) : closedMixin(theme)),
  },
}));

const SideList = ({ open, setOpen, isMobile }) => {
  const navigate = useNavigate();
  const [menuState, setMenuState] = useState({});
  const [activeTooltip, setActiveTooltip] = useState(null);

  const toggleMenu = (menuName, forceOpen = false) => {
    setMenuState((prev) => {
      const newState = Object.keys(prev).reduce(
        (acc, key) => ({
          ...acc,
          [key]: false, // Collapse all menus
        }),
        {}
      );
      return {
        ...newState,
        [menuName]: forceOpen || !prev[menuName],
      };
    });
  };

  const handleClick = (menuName) => {
    setActiveTooltip(null); // Force tooltip to close
    setOpen(true); // Expand the SideList
    toggleMenu(menuName, true); // Force open the clicked menu
  };

  const handleOptionClick = (subItemPath) => {
    navigate(subItemPath); // Navigate to the selected option
    toggleMenu(null); // Collapse all menus
    setOpen(false); // Close the SideList
  };

  const topLevelMenus = [
    { name: "List", icon: <Folder /> },
    { name: "Study", icon: <School /> },
    { name: "Quiz", icon: <Quiz /> },
    { name: "Resources", icon: <MenuBook /> },
    { name: "My Content", icon: <Person /> },
  ];

  const drawerContent = (
    <>
      <DrawerHeader>
        <IconButton onClick={() => setOpen(false)}>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {topLevelMenus.map((menu) => (
          <React.Fragment key={menu.name}>
            <ListItem disablePadding>
              {!open ? (
                <Tooltip
                  title={<Typography sx={{ fontSize: "1rem" }}>{menu.name}</Typography>}
                  placement="right"
                  arrow
                  open={activeTooltip === menu.name}
                >
                  <ListItemButton
                    onMouseEnter={() => setActiveTooltip(menu.name)} // Show tooltip
                    onMouseLeave={() => setActiveTooltip(null)} // Hide tooltip
                    onClick={() => handleClick(menu.name)} // Handle click
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>{menu.icon}</ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              ) : (
                <ListItemButton onClick={() => toggleMenu(menu.name)}>
                  <ListItemIcon sx={{ minWidth: 36 }}>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.name} />
                  {menuState[menu.name] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              )}
            </ListItem>
            <Collapse in={menuState[menu.name] && open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {nav
                  .filter((item) => item.parent === menu.name)
                  .map((subItem) => (
                    <ListItem key={subItem.path} disablePadding>
                      <ListItemButton sx={{ pl: 4 }} onClick={() => handleOptionClick(subItem.path)}>
                        <ListItemText primary={subItem.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      PaperProps={{ sx: { width: drawerWidth } }}
    >
      {drawerContent}
    </SwipeableDrawer>
  ) : (
    <Drawer variant="permanent" open={open}>
      {drawerContent}
    </Drawer>
  );
};

export default SideList;
