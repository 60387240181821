import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [persist, setPersist] = useState(true);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, persist, setPersist }}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
