import axios from "../utils/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setCurrentUser } = useAuth();

  const refresh = async () => {
    try {
      // console.log("Attempting refresh token...");
      const response = await axios.post(
        "/refreshToken", // The server will get the refresh token from the cookie
        {},
        { withCredentials: true } // Ensure cookies are sent
      );
      setCurrentUser((prev) => ({
        ...prev,
        accessToken: response.data.accessToken,
        id: response.data.userInfo.user_id,
        email: response.data.userInfo.user_email,
        firstName: response.data.userInfo.user_firstname,
        userStatus: response.data.userInfo.user_status,
        timeZone: response.data.userInfo.user_tz,
        darkMode: response.data.userInfo.user_dark_mode,
        clickMode: response.data.userInfo.user_click_mode,
        timer: response.data.userInfo.user_timer,
      }));
      return response.data.accessToken;
    } catch (err) {
      console.error("useRefreshToken: ", err);
      throw err;
    }
  };
  return refresh;
};

export default useRefreshToken;
