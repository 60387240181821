import React, { useState } from "react";
import { Avatar, Box, IconButton, Menu, MenuItem, ListItemIcon, Typography } from "@mui/material";
import { Settings, CreditCard, ExitToApp, VpnKey } from "@mui/icons-material"; // Updated icons
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";

const UserMenu = () => {
  const { currentUser } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await logout();
    handleMenuClose();
    navigate("/login", { replace: true });
  };

  return (
    <Box>
      {/* Avatar Button */}
      <IconButton onClick={handleMenuOpen}>
        {currentUser?.photoURL ? (
          <Avatar src={currentUser.photoURL} alt={currentUser?.name || "User Avatar"} />
        ) : (
          <Avatar>{currentUser?.firstName?.charAt(0).toUpperCase() || "U"}</Avatar>
        )}
      </IconButton>

      {/* User Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate("/user/settings");
          }}
        >
          {" "}
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Settings</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate("/user/subscription");
          }}
        >
          <ListItemIcon>
            <CreditCard fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Subscription</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate("/user/pwreset");
          }}
        >
          <ListItemIcon>
            <VpnKey fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Reset Password</Typography>
        </MenuItem>
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
