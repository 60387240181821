import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, AppBar, Toolbar, Button, IconButton, Avatar, Tooltip, Typography } from "@mui/material";
import { Home } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";

const TopNavBarPublic = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ backgroundColor: "hsl(39,100%,92%)", border: "2px solid", borderColor: "hsl(39,100%,50%)" }}>
        <Tooltip title="Go back to home page">
          <IconButton edge="start" sx={{ ml: 0 }} onClick={() => navigate("/")}>
            <Home />
          </IconButton>
        </Tooltip>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, ml: 1, mt: 0.25 }}>
          HSK Cards
        </Typography>
        <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
          {currentUser.accessToken ? (
            <IconButton onClick={() => navigate("/user")}>
              {currentUser?.photoURL ? (
                <Avatar src={currentUser.photoURL} alt={currentUser?.name || "User Avatar"} />
              ) : (
                <Avatar>{currentUser?.firstName?.charAt(0).toUpperCase() || "U"}</Avatar>
              )}
            </IconButton>
          ) : (
            <>
              <Button color="primary" variant="outlined" onClick={() => navigate("/login")} sx={{ mr: 2 }}>
                Login
              </Button>
              <Button color="primary" variant="outlined" onClick={() => navigate("/register")}>
                Register
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBarPublic;
