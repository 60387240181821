import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { useThemeContext } from "./ThemeContext"; // Import the custom hook

const Footer = () => {
  const { dark } = useThemeContext();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "background.default",
        color: "text.secondary",
        py: 2,
        mt: "auto",
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        sx={{
          alignItems: "center",
          justifyContent: { xs: "center", md: "space-between" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        {/* Copyright Section */}
        <Grid item xs={12} md="auto" sx={{ ml: { xs: 0, md: 10 } }}>
          <Typography variant="body2">&copy; {new Date().getFullYear()} HSK Cards. All rights reserved.</Typography>
        </Grid>

        {/* Links Section */}
        <Grid
          item
          xs={12}
          md="auto"
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            gap: 2,
            rowGap: 1,
            flexWrap: "wrap",
            mr: { xs: 0, md: 3 },
            fontSize: "14px",
          }}
        >
          <Typography
            component={Link}
            to="/about"
            sx={{ textDecoration: "none", fontSize: "14px", color: dark ? "hsl(0, 0%, 70%)" : "hsl(0,0%,40%)" }}
          >
            About
          </Typography>
          <Typography
            component={Link}
            to="/contact"
            sx={{ textDecoration: "none", fontSize: "14px", color: dark ? "hsl(0, 0%, 70%)" : "hsl(0,0%,40%)" }}
          >
            Contact
          </Typography>
          <Typography
            component={Link}
            to="/terms"
            sx={{ textDecoration: "none", fontSize: "14px", color: dark ? "hsl(0, 0%, 70%)" : "hsl(0,0%,40%)" }}
          >
            Terms of Service
          </Typography>
          <Typography
            component={Link}
            to="/privacy"
            sx={{ textDecoration: "none", fontSize: "14px", color: dark ? "hsl(0, 0%, 70%)" : "hsl(0,0%,40%)" }}
          >
            Privacy Policy
          </Typography>
          <Typography
            component={Link}
            to="/billofrights"
            sx={{ textDecoration: "none", fontSize: "14px", color: dark ? "hsl(0, 0%, 70%)" : "hsl(0,0%,40%)" }}
          >
            User Bill of Rights
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
