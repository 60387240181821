// src/Home.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Box, Container, Grid, TextField } from "@mui/material";

const Home = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
          backgroundColor: "lightgray",
          mb: 4,
        }}
      >
        <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
          [Placeholder for Video Introducing the App]
        </Typography>
      </Box>
      {/* Screenshots and Product Description */}
      <Container>
        <Typography variant="h4" component="h2" textAlign="center" gutterBottom>
          About Our Product
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                backgroundColor: "lightgray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>[Placeholder for Screenshot 1]</Typography>
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Screenshot 1 description goes here. Highlight the app's features and benefits.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
                height: "200px",
                backgroundColor: "lightgray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>[Placeholder for Screenshot 2]</Typography>
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Screenshot 2 description goes here. Show how the app solves problems.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Newsletter Signup */}
      <Box sx={{ mt: 6, py: 4, backgroundColor: "#f9f9f9" }}>
        <Container>
          <Typography variant="h5" component="h3" textAlign="center" gutterBottom>
            Sign Up for Our Newsletter
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
            Get the latest updates, news, and tips directly in your inbox.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TextField variant="outlined" placeholder="Enter your email" fullWidth sx={{ maxWidth: 400 }} />
            <Button variant="outlined" color="primary">
              Subscribe
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Pricing and Call-to-Action */}
      <Box sx={{ py: 4 }}>
        <Container>
          <Typography variant="h5" component="h3" textAlign="center" gutterBottom>
            Affordable Pricing
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
            Our service is free for the first 90 days, and then just $10 for six months with automatic renewal.
          </Typography>
          <Box textAlign="center">
            <Button variant="outlined" color="secondary" size="large" onClick={() => navigate("/register")}>
              Register Now
            </Button>
          </Box>
        </Container>
      </Box>

      <Box sx={{ py: 4 }}>
        <Container>
          <Typography variant="body1" textAlign="center" gutterBottom>
            Links for About and Contact pages
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
